import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { toCash, toDate } from '../../a_model/functions';

export function createPDF(data, result) {
console.log(result)
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let rateioGeral = 0
    let aulas = result
    
  
    for (let aula of aulas) {
        for (let i = aula.alunos.length - 1; i >= 0; i--) { // Itera de trás para frente para evitar problemas com splice
            let aluno = aula.alunos[i];
           
            if (aluno.status) {
                if (aula.rateio_modelo && aluno.tipo == 2) {
                    aluno.new_valor = parseFloat(aluno.valor);
                } else if (aluno.tipo == 1) {
                    aluno.new_valor = parseFloat(aluno.valor) / 100 * aula.rateio;
                }else{
                    aula.alunos.splice(i, 1)
                }
            } else {
                aula.alunos.splice(i, 1)
            }
        }
    }
    



    function getRateioAula(aula) {
        let retorno = 0;
        for (let aluno of aula.alunos) {
       
            if (aluno.status) {
                if (aula.rateio_modelo && aluno.tipo == 2) {
                    retorno += parseFloat(aluno.valor)
                } else if (aluno.tipo == 1) {
                    retorno += parseFloat(aluno.valor) / 100 * aula.rateio
                }
            }
        }
        rateioGeral += retorno

        return retorno;
    }

    function getRateioAluno(rateio_modelo, aluno){
        if(aluno.tipo == 1){

        }
       // !rateio_modelo ? toCash(0) : rateio.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    const title = [
        {
            table: {
                widths: ['100%'],
                body: [
                    [
                        {
                            text: `Relatório por período: ${toDate(data.data)} à ${toDate(data.data_fim)}
                            \nUnidade: IECB`,
                            fontSize: 15,
                            bold: true,
                            margin: [5, 5, 20, 5],
                            lineHeight: 0.7
                        }
                    ]
                ]
            }
        }
    ];

    const pagamentos = result.map((aula) => {
        const resultAula = aula.alunos.map((aluno) => {
            const valor = parseFloat(aluno.valor);
            const count = parseInt(aula.count);

            // Verifique se valor e count são válidos
            if (isNaN(valor) || isNaN(count)) {
                console.error('Valor inválido:', { valor, count, aula, aluno });
                return null;
            }

            const rateio = (valor / 100 * (count > 3 ? aula.rateio_regular : aula.rateio));


            return [
                {
                    text: `${aluno.tipo == 1 ? 'A' : 'M'}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2]
                },
                {
                    text: `${aluno.nome_aluno}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2]
                },
                {
                    text: `${toCash(valor)}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                },
                {
                    text: `${toCash(aluno.new_valor)}`,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                },
                {
                    text: `${aluno.status ? 'Pago' : 'Pendente'}`,
                    italics: true,
                    fontSize: 11,
                    margin: [0, 2, 0, 2],
                    noWrap: true
                }
            ];
        }).filter(item => item !== null); // Filtra itens nulos

        const totalRow = [
            {},
            {
                text: 'Total',
                bold: true,
                fontSize: 13,
                margin: [0, 2, 0, 2],

                noWrap: true
            },
            {
                text: ``,
                bold: true,
                fontSize: 13,
                margin: [0, 2, 0, 2],
                noWrap: true
            },
            {
                text: `${toCash(getRateioAula(aula))}`,
                bold: true,
                fontSize: 13,
                margin: [0, 2, 0, 2],
                noWrap: true
            },
            {}
        ];

        return [
            {
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: `${aula.nome_docente} - ${aula.nome_curso}`,
                                fontSize: 16,
                                margin: [2, 2, 2, 2]
                            }
                        ]
                    ]
                },
                layout: 'noBorders',
                margin: [2, 2, 2, 2]
            },
            {
                table: {
                    headerRows: 1,
                    widths: [10, 200, 70, 70, 30],
                    body: [
                        [
                            { border: [true, true, true, true], text: '', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: 'Nome', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: 'Valor', style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: `Rateio`, style: 'tableHeader', fontSize: 13, bold: true },
                            { border: [true, true, true, true], text: '*', style: 'tableHeader', fontSize: 13, bold: true }
                        ],
                        ...resultAula,
                        totalRow
                    ]
                },
                layout: 'lightHorizontalLines'
            }
        ];
    });

    const totalGeral = {
        table: {
            widths: ['auto', 'auto'],
            body: [
                [
                    {
                        text: 'Total Rateio',
                        bold: true,
                        fontSize: 15,
                        alignment: 'left',
                        margin: [0, 5, 0, 5],
                        decoration: 'underline'
                    },
                    {
                        text: `${toCash(rateioGeral)}`,
                        bold: true,
                        fontSize: 15,
                        margin: [0, 5, 0, 5],
                        decoration: 'underline',
                        noWrap: true
                    }
                ]
            ]
        },
        layout: 'noBorders',
        margin: [0, 10, 0, 0]
    };

    const pdfContent = [
        ...pagamentos.flat(),
        totalGeral
    ];

    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 15, 15, 15],
        content: [
            title,
            ...pdfContent
        ],
        layout: 'lightHorizontalLines',
        pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
        }
    };

    pdfMake.createPdf(docDefinitions).open();
}
