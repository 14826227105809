import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getUnidade, toDate } from "../a_model/functions";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function generateChecklistPDF(resposta, categorias, statusList, notasColor) {
  // Criação do conteúdo dinâmico do checklist
  const content = [
    { text: "Checklist de Respostas", style: "header" },
    {
      text: `Data: ${toDate(resposta.data)}\nEmpresa: ${getUnidade(resposta.unidade)}\nUsuário: ${resposta.usuario}`,
      style: "subheader",
    },
  ];

  // Agrupando itens por categoria
  const categoriasMap = resposta.itens.reduce((acc, item) => {
    if (!acc[item.categoria]) acc[item.categoria] = [];
    acc[item.categoria].push(item);
    return acc;
  }, {});

  // Adicionar categorias e suas tabelas
  Object.entries(categoriasMap).forEach(([categoriaId, itens]) => {
    const categoriaNome =
      categorias.find((cat) => cat.id == categoriaId)?.nome || "Desconhecida";

    const notaCategoria =
      resposta.notas.find((nota) => nota.categoria == categoriaId)?.nota || "-";

    // Cabeçalho da categoria com nota
    content.push({
      text: `${categoriaNome} - Nota: ${notaCategoria}`,
      style: "category",
      margin: [0, 10, 0, 5],
    });

    // Criar a tabela da categoria
    const tableBody = [
      // Cabeçalho da tabela
      [
        { text: "Item", bold: true },
        { text: "Status", bold: true },
        { text: "Observação", bold: true },
      ],
      // Linhas da tabela
      ...itens.map((item) => [
        item.nome,
        item.status > 0
          ? statusList.find((s) => s.id == item.status)?.nome || item.status
          : "Não definido",
        item.obs || "Nenhuma observação",
      ]),
    ];

    // Adiciona a tabela ao conteúdo
    content.push({
      table: {
        headerRows: 1,
        widths: ["*", "auto", "*"], // Define larguras das colunas
        body: tableBody,
      },
      layout: "lightHorizontalLines", // Layout da tabela
      margin: [0, 0, 0, 10], // Espaçamento abaixo da tabela
    });
  });

  // Adicionar as considerações finais
  content.push({
    text: "Considerações Finais",
    style: "subheader",
    margin: [0, 20, 0, 10],
  });

  content.push(
    {
      text: `Nota Usuário: ${resposta.nota_usuario || "<vazio>"}`,
      margin: [0, 0, 0, 5],
    },
    {
      text: `Nota Gerência: ${resposta.nota_gerencia || "<vazio>"}`,
      margin: [0, 0, 0, 5],
    },
    {
      text: `Nota Administração: ${resposta.nota_adm || "<vazio>"}`,
      margin: [0, 0, 0, 5],
    }
  );

  // Definição do estilo do documento
  const docDefinition = {
    content,
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      category: {
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 5],
      },
    },
  };

  // Gerar e abrir o PDF
  pdfMake.createPdf(docDefinition).open();
}
