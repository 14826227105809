import { useEffect, useState } from 'react';
import React from 'react';
import { Page } from '../a_model/Container';
import { Header } from '../a_model/Header';
import { Dialog } from '../a_model/Dialog';
import List from './List'
import Insert from './Insert'
import Options from './Options'
import Context from './Context'
import { useParams } from 'react-router';
import { InputDate, Select } from '../a_model/Input';
import moment from 'moment';


export default function Home() {
  const unidades = [
    { id: '*', nome: 'Todas' },
    { id: 1, nome: 'Matriz' },
    { id: 3, nome: 'SPA' },
    { id: 10, nome: 'Altamira' },
    { id: 11, nome: 'Paulinia' },
    { id: 13, nome: 'Brasília' },
  ]
  const { user, unidade } = useParams()
  const [step, setStep] = useState(0)
  const [text, setText] = useState(false)
  const [params, setParams] = useState({
    unidade,
    data: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    data_fim: moment().format('YYYY-MM-DD'),

  })
  const navList = ['Cadastrar', 'Respostas']
  const navListAdmin = ['Cadastrar', 'Respostas', 'Editar']

  const steps = [
    <Insert />,
    <List />,
    <Options />,

  ]

  useEffect(() => {


  }, [])

  const categorias = [

    { id: '0', sigla: '*', nome: 'Sem categoria' },
    { id: 1, sigla: 'CR', nome: 'Ações comerciais / Recepção' },
    { id: 2, sigla: 'R', nome: 'Recepção' },
    { id: 3, sigla: 'CH', nome: 'Chat' },
    { id: 4, sigla: 'AT', nome: 'Equipe de atendimento' },
    { id: 5, sigla: 'AV', nome: 'Equipe de avaliação' },
    { id: 6, sigla: 'VG', nome: 'Vendas gerais' },
    { id: 7, sigla: 'VA', nome: 'Vendas por avaliadora' },
    { id: 8, sigla: 'AI', nome: 'Ações internas' },

  ]

  const statusList = [
    { id: 3, nome: 'Excelente', color: '#1e971e' },
    { id: 2, nome: 'Regular', color: '#cea907' },
    { id: 1, nome: 'Ruim', color: '#b31c1c' },
  ]

  function dialog(value) {
    setText(value)
  }

  function handleChange(e) {
    setParams({ ...params, [e.target.name]: e.target.value });
  }

  return (
    <Context.Provider value={{ unidades, params, setStep, dialog, user, unidade, categorias, statusList }}>

      <Page>
        <Header
          setStep={setStep}
          navList={user === 'dradebora' || user === 'fellipe' ? navListAdmin : navList}
          buttons={[]}
        >
          {step === 1 &&
            <>
              <InputDate
                value={params.data}
                name='data'
                handleOnChange={handleChange}
              />
              <InputDate
                value={params.data_fim}
                name='data_fim'
                handleOnChange={handleChange}
              />
              {unidade == 1 &&
                <Select
                  options={unidades}
                  name='unidade'
                  value={params.unidade}
                  handleOnChange={handleChange}
                />
              }
            </>
          }
        </Header>
        <Dialog value={text} />
        <body>
          {steps[step]}
        </body>
      </Page>
    </Context.Provider>
  )

}
